import { render, staticRenderFns } from "./manager.vue?vue&type=template&id=9b192886&scoped=true"
import script from "./manager.vue?vue&type=script&lang=js"
export * from "./manager.vue?vue&type=script&lang=js"
import style0 from "../../editor.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./manager.vue?vue&type=style&index=1&id=9b192886&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_ubrbtih2qfyokjyfwkrqqblaa4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b192886",
  null
  
)

export default component.exports